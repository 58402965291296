<template>
  <base-section id="map" space="0">
    <br />
    <br />
    <v-container>
      <v-row justify="space-between">
        <v-col cols="12" md="5">
          <div>
            <base-info-card title="KEEP IN TOUCH WITH US" pace="6">
              For all inquires email us at info@mulliganhospitality.ie & we'll
              get back to you as soon as possible.
              <br />
            </base-info-card>
            <base-btn
              class="elevation-3"
              large
              style="width: 100%;"
              outlined
              to="/our-story"
            >
              OUR STORY
            </base-btn>
          </div>
        </v-col>

        <v-col cols="12" md="6">
          <div>
            <base-info-card
              title="SEND US A MAIL"
              :subtitle="subtitle"
              space="4"
            />
            <base-business-contact dense />
            <base-btn
              style="width: 100%;"
              href="mailto:info@mulliganhospitality.ie?subject=MHG%20Enquiry"
              target="_blank"
            >
              Click here to send us a message
            </base-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <iframe
      allowfullscreen
      aria-hidden="false"
      frameborder="0"
      height="500"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2381.958773318267!2d-6.265597183936697!3d53.343994182588204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48670e9cc27aa243%3A0x57be8feee940a1c8!2s32%20Dame%20St%2C%20Dublin%2C%20D02%20EA24%2C%20Ireland!5e0!3m2!1sen!2ses!4v1650636591869!5m2!1sen!2ses"
      style="border:0; display: block;"
      tabindex="0"
      width="100%"
    />
  </base-section>
</template>

<script>
export default {
  name: "SectionMap",

  provide: {
    heading: { align: "center" }
  }
};
</script>
